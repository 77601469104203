.front-placeholder[data-v-5678acfa] {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #0e2342;
}
.front-placeholder .lantern-row[data-v-5678acfa] {
    position: relative;
    height: 13.98148vh;
    line-height: 1;
    border-bottom: 2px solid #051121;
}
.front-placeholder .lantern-row .lantern-img[data-v-5678acfa] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      float: right;
      margin-top: 2.59259vh;
      margin-right: 1.57%;
      width: 4.6875vw;
      height: 4.6875vw;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;
}
.front-placeholder .lantern-row .lantern-img img[data-v-5678acfa] {
        height: 80%;
}
.front-placeholder .card-right__wrapper[data-v-5678acfa] {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: fixed;
    right: 0;
    top: 0;
}
.front-placeholder .card-right__wrapper .card-right[data-v-5678acfa] {
      float: right;
      width: 520px;
      margin-top: 20px;
}
.front-placeholder .main-img[data-v-5678acfa] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background-image: url(../../static/img/bg.5c55e915.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}
.front-placeholder .main-img .user-info[data-v-5678acfa] {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 3.4375vw;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
}
.front-placeholder .main-img .user-info > p[data-v-5678acfa]:first-child {
        font-size: 36px;
        margin-bottom: 15px;
}
.front-placeholder .main-img .user-info > p + p[data-v-5678acfa] {
        font-size: 24px;
}
