.popover-wrapper {
  background-color: #051022;
  padding: 0;
  border: none;
}
.popover-wrapper .popper__arrow {
    border-left-color: #051022 !important;
    -webkit-filter: none;
            filter: none;
    z-index: 999;
}
.popover-wrapper .popover-dialog {
    color: #fff;
}
.popover-wrapper .popover-dialog h3 {
      height: 40px;
      line-height: 40px;
      padding-left: 19px;
      font-size: 24px;
      margin-bottom: 37px;
      background-color: #03090e;
}
.popover-wrapper .popover-dialog .popover-content {
      width: 500px;
      height: 200px;
      padding: 0 30px 22px 54px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      overflow: hidden;
}
.popover-wrapper .popover-dialog .popover-content .img-container {
        float: left;
        width: 50px;
        height: 68px;
        margin-right: 42px;
}
.popover-wrapper .popover-dialog .popover-content .img-container img {
          width: 100%;
          height: 100%;
}
.popover-wrapper .popover-dialog .popover-content p {
        float: left;
        width: calc(100% - 92px);
        line-height: 1.5;
        font-size: 24px;
}
.popover-wrapper .popover-dialog .popover-content p span {
          color: #ff6665;
          margin: 0 0.5em;
}
.popover-wrapper .popover-dialog .popover-content .el-button {
        float: right;
        width: 94px;
        padding: 0;
        height: 36px;
        margin-top: 36px;
        background: #98b5d2;
        border-radius: 3px;
        color: #fff;
        font-size: 18px;
}
